<template>
  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="submit">
      <h6 class="mt-4">What kind of support can you provide?</h6>
      <v-radio-group v-model="radioGroup" class="pl-3">
        <v-row>
          <v-radio
            label="Ukraine"
            value="Ukraine"
          ></v-radio>
          <v-radio
            label="Poland"
            value="Poland"
          ></v-radio>
          <v-radio
            label="USA"
            value="Ukraine"
          ></v-radio>
        </v-row>
      </v-radio-group>
      <h6 class="mt-4">What kind of support can you provide?</h6>
      <v-checkbox
        v-for="selection in supportSelections"
        :key="selection.label"
        v-model="supportSelected"
        :label="selection.label"
        :value="selection.label"
        class="py-4"
      ></v-checkbox>
      <v-checkbox
      v-model="selected"
      label="John"
      value="John"
    ></v-checkbox>
    <h6 class="mt-4">Enter your details</h6>
      <validation-provider
        v-slot="{ errors }"
        name="FirstName"
        rules="required|max:20"
      >
        <v-text-field
          v-model="firstName"
          :counter="20"
          :error-messages="errors"
          label="First Name"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="LastName"
        rules="required|max:20"
      >
        <v-text-field
          v-model="lastName"
          :counter="20"
          :error-messages="errors"
          label="Last Name"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="phoneNumber"
        :rules="{
          required: true,
          digits: 7,
          regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
        }"
      >
        <v-text-field
          v-model="phoneNumber"
          :counter="7"
          :error-messages="errors"
          label="Phone Number"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="email"
        rules="required|email"
      >
        <v-text-field
          v-model="email"
          :error-messages="errors"
          label="E-mail"
          required
        ></v-text-field>
      </validation-provider>
      <!-- <validation-provider
        v-slot="{ errors }"
        name="select"
        rules="required"
      >
        <v-select
          v-model="select"
          :items="items"
          :error-messages="errors"
          label="Select"
          data-vv-name="select"
          required
        ></v-select>
      </validation-provider> -->
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="checkbox"
      >
        <v-checkbox
          v-model="checkbox"
          :error-messages="errors"
          value="1"
          label="I declare that I have read / familiarized myself with Website privacy policy *"
          type="checkbox"
          required
        ></v-checkbox>
      </validation-provider>

      <input v-if="(selectedUser=='Individual')" type="hidden" name="userType" value="Individual">
      <input v-if="(selectedUser=='Non-Governmental Organization')" type="hidden" name="userType" value="Non-Governmental Organization">
      <input v-if="(selectedUser=='Company')" type="hidden" name="userType" value="Company">
      <input v-if="(selectedUser=='Institution')" type="hidden" name="userType" value="Institution">

      <v-btn
        class="mr-4"
        type="submit"
        :disabled="invalid"
      >
        submit
      </v-btn>
      <v-btn @click="clear">
        clear
      </v-btn>
    </form>
  </validation-observer>
</template>

<script>
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      selectedUser: {
        type: String,
        required: true
      }
    },
    data: () => ({
      supportSelections: [
        {
          label: "Food",
          value: "Food"
        },
        {
          label: "Other material support – e.g. clothes, medications",
          value: "Other material support – e.g. clothes, medications"
        },
        {
          label: "Shelter – e.g. flat, room",
          value: "Shelter – e.g. flat, room"
        },
        {
          label: "Volunteer support",
          value: "Volunteer support"
        }
      ],
      radioGroup: null,
      supportSelected: [],
      name: '',
      phoneNumber: '',
      email: '',
      select: null,
      checkbox: null,
    }),

    methods: {
      submit () {
        this.$refs.observer.validate()
      },
      clear () {
        console.log(this.selectedUser)
        this.firstName = ''
        this.lastName = ''
        this.phoneNumber = ''
        this.email = ''
        this.select = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
    },
  }
</script>