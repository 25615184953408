<template>
  <v-container fluid class="px-12">
    <v-row class="text-left py-12">
      <v-col md="4" cols="12" class="pa-12 d-none d-md-block">
        <h5 class="text-h6 py-4">{{ $t('mission.HEADER') }}</h5>
        <h2 class="primary--text text-h4">{{ $t('mission.TITLE') }}</h2>
      </v-col>
      <v-col md="4" cols="12" class="pa-4 d-md-none text-right">
        <h5 class="text-h6 py-4">{{ $t('mission.HEADER') }}</h5>
        <h2 class="primary--text text-h4">{{ $t('mission.TITLE') }}</h2>
      </v-col>
      <v-col md="8" cols="12" class="pa-12">
        <v-row class="text-left">
          <v-col v-for="card in cards" :key="card.title" md="4" cols="12">
            <v-card class="pa-4" height="18em">
            <v-img width="40px" :src="card.icon" />
            <!-- <v-icon color="blue py-4" src="img/mission/act.png"></v-icon> -->
            <br />
            <span class="text-h6"><b class="primary--text">{{ card.title }}</b>{{ card.subtitle }}</span>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <donate-button />
  </v-container>
</template>

<script>
import DonateButton from './common/DonateButton'
  export default {
    name: 'OurMission',

    data: () => ({
    }),
    components: {
      DonateButton
    },
    computed: {
      cards: function () {
        return [
          {
            title: this.$t('mission.CARD1_TITLE'),
            subtitle: this.$t('mission.CARD1_SUBTITLE'),
            icon: "img/mission/compass.png"
          },
          {
            title: this.$t('mission.CARD2_TITLE'),
            subtitle: this.$t('mission.CARD2_SUBTITLE'),
            icon: "img/mission/network.png"
          },
          {
            title: this.$t('mission.CARD3_TITLE'),
            subtitle: this.$t('mission.CARD3_SUBTITLE'),
            icon: "img/mission/act.png"
          }
        ]
      }
    }
  }
</script>
