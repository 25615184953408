<template>
  <v-app-bar
      app
      dark
      fixed
      hide-on-scroll
      height="80"
      color="transparent"
      class="elevation-0 primary pa-8 appbar"
    >
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 ml-15"
            contain
            src="dovelogo.png"
            transition="scale-transition"
            width="80"
          />
        </router-link>
        <router-link to="/">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="logo.png"
            transition="scale-transition"
            width="120"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>
      <div class="btn-wrapper d-none d-md-block">
        <v-btn
          text
          outlined
          class="mx-1 language-selector appbar-btn"
        >
          En
        </v-btn>
        <join-button />
        <v-btn          
          text
          class="mx-1 white primary--text appbar-btn mr-15"
          @click="scroll('contact')"
        >
          Contact Us
        </v-btn>
        <v-btn          
          text
          class="mx-1 white primary--text appbar-btn mr-15"
          to="login"
        >
          Sign In
        </v-btn>
      </div>
      <v-app-bar-nav-icon class="d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        app
        temporary
        right
      >
        <h2 class="ma-4">MT6 Foundation</h2>
        <v-list
          nav
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-btn
                text
                outlined
                class="language-selector appbar-btn"
              >
                En
              </v-btn>
            </v-list-item>

            <v-list-item>
              <join-button />
            </v-list-item>

            <v-list-item>
              <v-btn          
                text
                class="white primary--text appbar-btn"
                @click="scroll('contact')"
              >
                Contact Us
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
</template>

<script>
import JoinButton from '../common/JoinButton'

  export default {
    name: 'AppHeader',

    components: {
      JoinButton
    },
    data: () => ({
      drawer: false,
    }),
    methods: {
      scroll(id) {  
      this.drawer = false;
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
</script>

<style scoped>
.appbar {
  padding: 0 !important;
}

.btn-wrapper > .appbar-btn {
  font-family: "Khula";
  font-weight: 800 !important;
  font-size: 14px !important;
  letter-spacing: -.25px;
}

.language-selector {
  min-width: 70px !important;
  border: 1px solid white !important;
}

.yellow-button{
 background-image: linear-gradient(180deg, #FDFF94 0%, #DFC900 100%);

}

</style>
