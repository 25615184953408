<template>
  <v-container fluid class="px-12">
    <v-card class="pa-12 ma-12">
      <h5 class="text-h6 py-2 text-center">fundraising</h5>
        <h2 class="text-h4 primary--text py-2 text-center">Current Efforts</h2>
      <v-row justify="center">
        <v-col cols="12">
          <v-slide-group
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="effort in efforts"
              :key="effort.title"
              class="pa-2 ma-4"
            >
              <v-card
                :loading="loading"
                class="mx-2 my-12"
                max-width="300"
              >
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                  height="250"
                  :src="effort.img"
                ></v-img>

                <v-card-title>{{ effort.title }}</v-card-title>

                <v-card-text>
                  <v-row
                    align="center"
                    class="mx-0"
                  >
                    <!-- <v-rating
                      :value="4.5"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating> -->

                    <!-- <div class="grey--text ms-4">
                      4.5 (413)
                    </div> -->
                  </v-row>

                  <div class="my-4 text-subtitle-1">
                    {{ effort.location }}
                  </div>

                  <div>{{ effort.subtitle }}</div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>Current Status - <small> {{effort.status}}</small></v-card-title>
                

                <v-card-text>
                  <v-row justify="space-between">
                    <v-col cols="4">
                      <h4>Raised - {{effort.amountRaised}}</h4>
                    </v-col>
                    <v-col cols="4">
                      <h4>Goal - {{effort.goalAmount}}</h4>
                    </v-col>
                  </v-row>
                  
                  <v-progress-linear
                    color="light-green darken-4"
                    height="10"
                    :value="effort.percentRaised"
                    striped
                  ></v-progress-linear>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="reserve"
                    class="ml-2"
                  >
                    Donate
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>    
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'OurMission',

    data: () => ({
      model: null,
      efforts: [
        {
          title: 'Food for Mariupol',
          subtitle: 'There is currently a food shortage in the yatta yatta yatta region of Mauripol',
          img: 'img/articles/1.png',
          location: "Mariupol, Ukraine",
          goalAmount: '5,000',
          amountRaised: '3000',
          percentRaised: 60,
          status: 'ongoing',
          deadline: 'open-ended'
        },
        {
          title: 'Food for Mariupol 2',
          subtitle: 'There is currently a food shortage in the yatta yatta yatta region of Mauripol',
          img: 'img/articles/2.png',
          location: "Mariupol, Ukraine",
          goalAmount: '15,000',
          amountRaised: '3000',
          percentRaised: 20,
          status: 'ongoing',
          deadline: 'open-ended'
        },
        {
          title: 'Food for Mariupol 3',
          subtitle: 'There is currently a food shortage in the yatta yatta yatta region of Mauripol',
          img: 'img/articles/3.png',
          location: "Mariupol, Ukraine",
          goalAmount: '10,000',
          amountRaised: '3,000',
          percentRaised: 30,
          status: 'ongoing',
          deadline: 'open-ended'
        },
        {
          title: 'Food for Mariupol 4',
          subtitle: 'There is currently a food shortage in the yatta yatta yatta region of Mauripol',
          img: 'img/articles/4.png',
          location: "Mariupol, Ukraine",
          goalAmount: '5,000',
          amountRaised: '3000',
          percentRaised: 60,
          status: 'ongoing',
          deadline: 'open-ended'
        },
        {
          title: 'Food for Mariupol 4',
          subtitle: 'There is currently a food shortage in the yatta yatta yatta region of Mauripol',
          img: 'img/articles/4.png',
          location: "Mariupol, Ukraine",
          goalAmount: '5,000',
          amountRaised: '3000',
          percentRaised: 60,
          status: 'ongoing',
          deadline: 'open-ended'
        }
      ]
    }),
    computed: {
      cards: function () {
        return [
          {
            title: this.$t('mission.CARD1_TITLE'),
            subtitle: this.$t('mission.CARD1_SUBTITLE'),
            icon: "img/mission/compass.png"
          },
          {
            title: this.$t('mission.CARD2_TITLE'),
            subtitle: this.$t('mission.CARD2_SUBTITLE'),
            icon: "img/mission/network.png"
          },
          {
            title: this.$t('mission.CARD3_TITLE'),
            subtitle: this.$t('mission.CARD3_SUBTITLE'),
            icon: "img/mission/act.png"
          }
        ]
      }
    }
  }
</script>
