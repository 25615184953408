import Vue from 'vue'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import '@/plugins/common'
import '@/plugins/veevalidate'
import i18n from '@/plugins/i18n'
// import VuetifyConfirm from 'vuetify-confirm'

Vue.config.productionTip = false
// Vue.use(VuetifyConfirm, { vuetify })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {
    store.dispatch('setLocale', store.getters.locale)
    if (store.getters.isTokenSet) {
      store.dispatch('autoLogin')
    }
  }
}).$mount('#app')

// if (window.Cypress) {
//   // Only available during E2E tests
//   window.app = App
// }
