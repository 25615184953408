export default [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import('@/views/LoginView.vue')
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import('../../views/SignUpView.vue')
  },
  // {
  //   path: '/verify/:id',
  //   name: 'verify',
  //   component: () =>
  //     import(/* webpackChunkName: "verify" */ '@/components/Verify.vue')
  // },
  {
    path: '/forgot',
    name: 'forgotPassword',
    component: () =>
      import(
        '@/views/ForgotPassword.vue'
      )
  },
  // {
  //   path: '/reset/:id',
  //   name: 'resetPassword',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "resetPassword" */ '@/components/ResetPassword.vue'
  //     )
  // }
]
