<template>
  <v-tab-item>
    <v-card flat class="pa-12">
    <v-card-text>
      <div class="py-4" v-if="(this.user=='Institution')">
        <h2>Help as an institution</h2>
        <p>Choose the institution you represent and fill out a simple form. We will send it to the appropriate unit that coordinates aid for refugees.</p>
        <p>Please fill out the form and someone on our team will get back to you as soon as possible!</p>
          <help-form :selectedUser="user" />
        </div>
        <div class="py-4" v-if="(this.user=='Individual')">
          <h2>Help as an individual</h2>
          <p>Please fill out the form and someone on our team will get back to you as soon as possible!</p>
          <help-form :selectedUser="user" />
        </div>
        <div class="py-4" v-if="(user=='Non-Governmental Organization')">
          <h2>Help as an Non-Governmental Organization</h2>
          <p>Please fill out the form and someone on our team will get back to you as soon as possible!</p>
          <help-form :selectedUser="user" />
        </div>
         <div class="py-4"> 
          <h4>Send a humanitarian convoy</h4>
          <p>Find out how to use the simplified border crossing procedure.</p>
        </div>
      </v-card-text>
    </v-card>
  </v-tab-item>
</template>

<script>
import HelpForm from './HelpForm'

  export default {
    name: 'WantHelp',
    components: {
      HelpForm
    },
    props: {
      user: {
        type: String,
        required: true
      }
    },
    data: () => ({
      userTypes: [
        {
          title: "Indiviual",
          icon: "mdi-home"
        },
        {
          title: "Non-Governmental Organization",
          icon: "mdi-home"
        },
        {
          title: "Company",
          icon: "mdi-home"
        }
      ],
      helpButtons: [
        {
          title: "Arrival to Poland",
          icon: "mdi-home"
        },
        {
          title: "Polish Telephone Number",
          icon: "mdi-home"
        },
        {
          title: "PESEL",
          icon: "mdi-home"
        },
        {
          title: "Information Points",
          icon: "mdi-home"
        },
        {
          title: "Hotlines",
          icon: "mdi-home"
        },
        {
          title: "Overnight Stays and Housing",
          icon: "mdi-home"
        },
        {
          title: "Material Collections",
          icon: "mdi-home"
        },
        {
          title: "Transport",
          icon: "mdi-home"
        },
        {
          title: "Health",
          icon: "mdi-home"
        },
        {
          title: "Legal Help",
          icon: "mdi-home"
        },
        {
          title: "Work",
          icon: "mdi-home"
        },
        {
          title: "Translations",
          icon: "mdi-home"
        },
      ],
      individualButtons: [
        {
          title: "Arrival to Poland",
          icon: "mdi-home"
        },
        {
          title: "Financial Support",
          icon: "mdi-home"
        },
        {
          title: "Transport",
          icon: "mdi-home"
        },
        {
          title: "Accomodation",
          icon: "mdi-home"
        },
        {
          title: "Hotlines",
          icon: "mdi-home"
        },
        {
          title: "Overnight Stays and Housing",
          icon: "mdi-home"
        },
        {
          title: "Material Collections",
          icon: "mdi-home"
        },
        {
          title: "Transport",
          icon: "mdi-home"
        },
        {
          title: "Volunteering",
          icon: "mdi-home"
        },
        {
          title: "Translations",
          icon: "mdi-home"
        }
      ],
      tabTitles: [
        {
          title: "I need help"
        },
        {
          title: "I want to help"
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    })
  }
</script>