<template>
  <v-card class="fillWidth" id="helpTabs">
    <v-toolbar
      dark
      flat
    >
      <template v-slot:extension>
        <v-tabs
          v-model="propTabs"
          centered
        >
          <v-tab
            v-for="tab in tabTitles"
            :key="tab.title"
          >
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <div class="pa-12" v-if="!userType">
      <h2>Need Help?</h2>
      <br />
      <h2>Are you a(n): </h2>
      <v-row class="py-4">
        <v-col v-for="type in userTypes" :key="type.title" cols="4">
          <v-card height="300px" class="pa-4 text-center fill-width" @click="showUserTypePrompt(type.title)">
            <v-row justify="center" align="center" class="fill-height">
              <v-col cols="12">
                <v-icon class="my-1">{{ type.icon }}</v-icon>
                <h2>{{ type.title }}</h2>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-tabs-items v-model="propTabs" v-if="userType">
      <need-help :user="userType" />
      <want-help :user="userType" />
    </v-tabs-items>
  </v-card>
</template>

<script>
import NeedHelp from './helpTabs/NeedHelp'
import WantHelp from './helpTabs/WantHelp'

  export default {
    name: 'HelpTabs',
    props: {
      tabs: {
        type: Number,
        required: true
      }
    },
    components: {
      NeedHelp,
      WantHelp
    },
    data: () => ({
      userTypes: [
        {
          title: "Individual",
          icon: "mdi-home"
        },
        {
          title: "Non-Governmental Organization",
          icon: "mdi-home"
        },
        {
          title: "Institution",
          icon: "mdi-home"
        }
      ],
      userType: null,
      dialog: false,
      helpIsShowing: false,
      tabTitles: [
        {
          title: "I need help"
        },
        {
          title: "I want to help"
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    }),
    methods: {
      showHelp() {
        this.helpIsShowing = true
      },
      showUserTypePrompt(userSelection) {
        this.userType= userSelection
      }
    },
    computed: {
      propTabs: {
        get () { return this.tabs },
        set (value) { this.$emit('update:tabs', value) },
      },
    },
  }
</script>

<style scoped>
.fillWidth {
  width: 100%;
}
</style>