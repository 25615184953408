var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h6',{staticClass:"mt-4"},[_vm._v("What kind of support can you provide?")]),_c('v-radio-group',{staticClass:"pl-3",model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-row',[_c('v-radio',{attrs:{"label":"Ukraine","value":"Ukraine"}}),_c('v-radio',{attrs:{"label":"Poland","value":"Poland"}}),_c('v-radio',{attrs:{"label":"USA","value":"Ukraine"}})],1)],1),_c('h6',{staticClass:"mt-4"},[_vm._v("What kind of support can you provide?")]),_vm._l((_vm.supportSelections),function(selection){return _c('v-checkbox',{key:selection.label,staticClass:"py-4",attrs:{"label":selection.label,"value":selection.label},model:{value:(_vm.supportSelected),callback:function ($$v) {_vm.supportSelected=$$v},expression:"supportSelected"}})}),_c('v-checkbox',{attrs:{"label":"John","value":"John"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('h6',{staticClass:"mt-4"},[_vm._v("Enter your details")]),_c('validation-provider',{attrs:{"name":"FirstName","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"error-messages":errors,"label":"First Name","required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"LastName","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"error-messages":errors,"label":"Last Name","required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{
        required: true,
        digits: 7,
        regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":7,"error-messages":errors,"label":"Phone Number","required":""},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"value":"1","label":"I declare that I have read / familiarized myself with Website privacy policy *","type":"checkbox","required":""},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)}),((_vm.selectedUser=='Individual'))?_c('input',{attrs:{"type":"hidden","name":"userType","value":"Individual"}}):_vm._e(),((_vm.selectedUser=='Non-Governmental Organization'))?_c('input',{attrs:{"type":"hidden","name":"userType","value":"Non-Governmental Organization"}}):_vm._e(),((_vm.selectedUser=='Company'))?_c('input',{attrs:{"type":"hidden","name":"userType","value":"Company"}}):_vm._e(),((_vm.selectedUser=='Institution'))?_c('input',{attrs:{"type":"hidden","name":"userType","value":"Institution"}}):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }