<template>
  <v-tab-item>
    <v-card flat class="pa-12">
      <div v-if="(this.user=='Individual')">
        <h2>Get help as an individual</h2>
        <v-card-text>
          <div class="py-4">
            <h4 class="my-6">
              Need legal, medical, social assistance?
              <br />
              Find out what help you can get in Poland. Find out how to get support.
            </h4>
            <v-row justify="left">
              <v-col v-for="button in InvidualButtons" :key="button.title" md="2" cols="6">
                <v-card width="200px" class="pa-4 text-center" :href="button.href" target="_blank">
                  <v-icon class="my-1">{{ button.icon }}</v-icon>
                  <h5>{{ button.title }}</h5>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
      <div v-if="(this.user=='Institution')">
        <h2>Get help as an individual</h2>
        <v-card-text>
          <div class="py-4">
            <h4 class="my-6">
              Need legal, medical, social assistance?
              <br />
              Find out what help you can get in Poland. Find out how to get support.
            </h4>
            <v-row justify="left">
              <v-col v-for="button in InvidualButtons" :key="button.title" md="2" cols="6">
                <v-card width="200px" class="pa-4 text-center" :href="button.href" target="_blank">
                  <v-icon class="my-1">{{ button.icon }}</v-icon>
                  <h5>{{ button.title }}</h5>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
      <div v-if="(this.user=='Non-Governmental Organization')">
        <h2>Get help as an individual</h2>
        <v-card-text>
          <div class="py-4">
            <h4 class="my-6">
              Need legal, medical, social assistance?
              <br />
              Find out what help you can get in Poland. Find out how to get support.
            </h4>
            <v-row justify="left">
              <v-col v-for="button in InvidualButtons" :key="button.title" md="2" cols="6">
                <v-card width="200px" class="pa-4 text-center" :href="button.href" target="_blank">
                  <v-icon class="my-1">{{ button.icon }}</v-icon>
                  <h5>{{ button.title }}</h5>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-tab-item>
</template>

<script>
  export default {
    name: 'NeedHelp',
    props: {
      user: {
        type: String,
        required: true
      }
    },
    mounted() {
      console.log('mounted and user is: ', this.user)
    },
    data: () => ({
      InvidualButtons: [
        {
          title: "Arrival to Poland",
          icon: "mdi-home",
          href:"https://pomagamukrainie.gov.pl/potrzebuje-pomocy/przyjazd-do-polski"
        },
        {
          title: "Polish Telephone Number",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/pesel'
        },
        {
          title: "PESEL",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/pesel'
        },
        {
          title: "Information Points",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/punkty-info'
        },
        {
          title: "Hotlines",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/infolinie'
        },
        {
          title: "Overnight Stays and Housing",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/noclegi'
        },
        {
          title: "Material Collections",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/pomoc-rzeczowa'
        },
        {
          title: "Transport",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/transport'
        },
        {
          title: "Health",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/zdrowie'
        },
        {
          title: "Legal Help",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/wsparcie-prawne'
        },
        {
          title: "Work",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/praca'
        },
        {
          title: "Translations",
          icon: "mdi-home",
          href: 'https://pomagamukrainie.gov.pl/potrzebuje-pomocy/tlumaczenia'
        },
      ],
      tabTitles: [
        {
          title: "I need help"
        },
        {
          title: "I want to help"
        }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    })
  }
</script>
